.App {
  text-align: center;
  font-family: serif;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 250px;
  display: inline-block;
}

.App-header {
  background-color: #30A9E0;
  height: 375px;
  padding: 20px;
  color: black;
  text-shadow:1px 2px 3px white;
}

.App-title {
  font-size: 2.5em;
  line-height: 0.5;
  
  span {
    font-size:1rem;
  }
}

.App-intro {
  padding: 40px;
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
